if (document.querySelector(".hero2Swiper .swiper")) {
    const swiper = new Swiper(".hero2Swiper .swiper", {
        loop: "true",
        // autoplay: {
        //     delay: 5000,
        //     disableOnInteraction: false
        // },
        navigation: {
            nextEl: ".hero2Swiper .swiper-button-next",
            prevEl: ".hero2Swiper .swiper-button-prev",
        },
        pagination: {
            el: ".hero2Swiper .swiper-pagination",
            type: "fraction",
        },
    });
}

if (document.querySelector(".hero3Swiper .swiper")) {
    const swiper = new Swiper(".hero3Swiper .swiper", {
        loop: "true",
        // autoplay: {
        //     delay: 5000,
        //     disableOnInteraction: false
        // },
        navigation: {
            nextEl: ".hero3Swiper .swiper-button-next",
            prevEl: ".hero3Swiper .swiper-button-prev",
        },
        pagination: {
            el: ".hero3Swiper .swiper-pagination",
            type: "fraction",
        },
    });
}


if (document.querySelector(".tabsSwiper .swiper")) {
    const swiper = new Swiper(".tabs__navlist .swiper", {
        slidesPerView: "auto",
        spaceBetween: 20,
        freeMode: true,
        preventClicks: true,
        a11y: false,


        navigation: {
            nextEl: ".tabs__navlist .swiper-button-next",
            prevEl: ".tabs__navlist .swiper-button-prev",
        },
        pagination: {
            el: ".tabs__navlist .swiper-pagination",
            type: "fraction",
        },


        // a11y: {
        //     nextSlideMessage: "Następny slajd",
        //     prevSlideMessage: "Poprzedni slajd",
        //     firstSlideMessage: "Pierwszy slajd",
        //     lastSlideMessage: "Ostatni slajd",
        //     paginationBulletMessage: 'Przejdź na slajd {{index}}',
        //
        // },
    });
}


if (document.querySelectorAll(".mySwiper2T").length > 0) {
    var swiper = new Swiper(".mySwiperT", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        direction: "horizontal",
        breakpoints: {
            576: {
                direction: "vertical",
            },
        }
    });

    var swiper2 = new Swiper(".mySwiper2T", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
    var swiperProduct = new Swiper("#productSwiper", {
        slidesPerGroup: 1,

        loop: false,
        loopFillGroupWithBlank: false,
        pagination: {
            el: "#productSwiper .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: "#productSwiper .swiper-button-next",
            prevEl: "#productSwiper .swiper-button-prev",
        },
    });
}


// if (document.querySelector(".strategySwiper")) {
//
//     var swiper = new Swiper(".strategySwiper", {
//         loop: true,
//         spaceBetween: 8,
//         slidesPerView: 2,
//         freeMode: true,
//         watchSlidesProgress: true,
//         breakpoints: {
//             640: {
//                 spaceBetween: 20,
//             },
//         },
//         keyboard: true,
//         a11y: {
//             nextSlideMessage: "Następny slajd",
//             prevSlideMessage: "Poprzedni slajd",
//             firstSlideMessage: "Pierwszy slajd",
//             lastSlideMessage: "Ostatni slajd",
//             paginationBulletMessage: 'Przejdź na slajd {{index}}',
//
//         },
//
//     });
//     var swiper2 = new Swiper(".strategySwiper2", {
//         loop: true,
//         spaceBetween: 10,
//
//
//
//         thumbs: {
//             swiper: swiper,
//         },
//         pagination: {
//             el: ".swiper-pagination",
//         },
//     });
//
// }
//
//
// if (document.querySelector(".historySwiper .swiper")) {
//     const swiper = new Swiper(".historySwiper .swiper", {
//         slidesPerView: 1,
//         spaceBetween: 10,
//         freeMode: true,
//
//         navigation: {
//             nextEl: ".historySwiper .swiper-button-next",
//             prevEl: ".historySwiper .swiper-button-prev",
//         },
//
//         breakpoints: {
//             640: {
//                 slidesPerView: 2,
//             },
//             768: {
//                 slidesPerView: 2,
//             },
//             1200: {
//                 slidesPerView: 4,
//                 spaceBetween: 20,
//             },
//         },
//         keyboard: true,
//         a11y: {
//             nextSlideMessage: "Następny slajd",
//             prevSlideMessage: "Poprzedni slajd",
//             firstSlideMessage: "Pierwszy slajd",
//             lastSlideMessage: "Ostatni slajd",
//             paginationBulletMessage: 'Przejdź na slajd {{index}}',
//
//         },
//     });
// }
//
//
//
//
// if (document.querySelector(".investmentsSwiper")) {
//
//     var swiper = new Swiper(".investmentsSwiper2", {
//         loop: true,
//         spaceBetween: 0,
//         slidesPerView: 2,
//         freeMode: true,
//
//         allowTouchMove: false,
//         breakpoints: {
//
//             576: {
//                 spaceBetween: 12,
//             },
//             1200: {
//                 spaceBetween: 30,
//             }
//         },
//         keyboard: true,
//         a11y: {
//             nextSlideMessage: "Następny slajd",
//             prevSlideMessage: "Poprzedni slajd",
//             firstSlideMessage: "Pierwszy slajd",
//             lastSlideMessage: "Ostatni slajd",
//             paginationBulletMessage: 'Przejdź na slajd {{index}}',
//
//         },
//
//     });
//     var swiper2 = new Swiper(".investmentsSwiper", {
//         loop: true,
//         spaceBetween: 10,
//         effect:"fade",
//         autoplay: {
//             delay: 20000,
//             disableOnInteraction: false,
//         },
//
//
//         thumbs: {
//             swiper: swiper,
//         },
//
//     });
//
// }